/* Built In Imports */
/* External Imports */
import { Box, Link, List, ListItem } from '@chakra-ui/react';
import {
    EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

/* Internal Imports */
/* Components */
import LikeBookmark from '@components/Utility/LikeBookmark';
import config from '@config';

/* Services */

/**
 * Renders Social Icon Component
 *
 * @param {string} url - Link
 * @param {boolean} isLikeBookmarkVisible 
 * @param {boolean} isVisible
 * @param {boolean} isBookmarked
 * @param {string} position - Position of the component
 * @param {string} direction - Flex-direction  
 * @returns 
 */
const SocialIcon = ({
  url,
  isVisible,
  direction,
  position,
  isLikeBookmarkVisible = false,
  isLiked,
  isBookmarked,
  updateCards,
  freeSadhguruDownloadsId,
}) => {
  return (
    <List
      top={{
        base: '0',
        sm: '0',
        md: '20%',
        lg: '30%',
      }}
      bottom={{
        base: 'unset',
        sm: 'unset',
        md: '10%',
        lg: '5%',
      }}
      pos={position || 'sticky'}
      width={{
        base: '100%',
        md: position === 'relative' ? '100%' : '55px',
        lg: position === 'relative' ? '100%' : '85px',
      }}
      bgColor={{
        base: '#fff',
        sm: '#fff',
        md: 'transparent',
        lg: 'transparent',
      }}
      height={{
        lg: position === 'relative' ? 'auto' : '476px',
        md: position === 'relative' ? 'auto' : '100%',
        sm: position === 'relative' ? 'auto' : '40px',
        base: position === 'relative' ? 'auto' : '40px',
      }}
      borderRadius="0"
      mr="-85px"
      float="left"
      p={position === 'relative' ? 'unset' : '10px'}
      zIndex="5"
      listStyleType="none"
      display={{
        base: `${!isVisible || position === 'relative' ? 'flex' : 'none'}`,
        sm: `${!isVisible || position === 'relative' ? 'flex' : 'none'}`,
        md: `${isVisible || position === 'relative' ? 'flex' : 'none'}`,
        lg: `${isVisible || position === 'relative' ? 'flex' : 'none'}`,
      }}
      flexDirection={{
        base: direction || 'row',
        md: direction || 'column',
      }}
      alignItems="center"
      boxShadow={{
        base:
          position === 'relative' ? 'unset' : '1px 1px 11px 0 rgb(0 0 0 / 10%)',
        lg: 'unset',
      }}
      mb="20px"
      mt={{ base: '20px', md: 'unset' }}
      justifyContent="center"
      padding="0"
    >
      <ListItem w={position === 'relative' ? '60px' : '40px'} m="0 0.5vw">
        <WhatsappShareButton
          url={`${config.BASE_PATH}${url}`}
          title={''}
          separator=":: "
        >
          <Link mb={{ base: 0, sm: 0, md: 0, lg: '10px' }} display="block">
            <Box
              bgImage={`url(${config.staticPath}/d/46272/1663580397-whatsapp.svg)`}
              bgPosition="center"
              bgRepeat="no-repeat"
              _hover={{
                bgImage: `url('${config.staticPath}/assets/images/whatsapp1.svg')`,
              }}
              w="28px"
              h="28px"
              p="0 20px"
              bgSize="clip"
            ></Box>
          </Link>
        </WhatsappShareButton>
      </ListItem>
      <ListItem w="40px" m="0 0.5vw">
        <FacebookShareButton url={`${config.BASE_PATH}${url}`} quote={''}>
          <Link mb={{ base: 0, sm: 0, md: 0, lg: '10px' }} display="block">
            <Box
              bgImage={`url(${config.staticPath}/assets/images/facebook.svg)`}
              bgPosition="center"
              bgRepeat="no-repeat"
              _hover={{
                bgImage: `url('${config.staticPath}/assets/images/facebook1.svg')`,
              }}
              w="30px"
              h="30px"
              bgSize="clip"
            ></Box>
          </Link>
        </FacebookShareButton>
      </ListItem>
      <ListItem w="40px" m="0 0.5vw">
        <TwitterShareButton url={`${config.BASE_PATH}${url}`} title={''}>
          <Link mb={{ base: 0, sm: 0, md: 0, lg: '10px' }} display="block">
            <Box
              bgImage={`url(${config.staticPath}/assets/images/twitter.svg)`}
              bgPosition="center"
              bgRepeat="no-repeat"
              _hover={{
                bgImage: `url('${config.staticPath}/assets/images/twitter1.svg')`,
              }}
              w="28px"
              h="28px"
              bgSize="clip"
            ></Box>
          </Link>
        </TwitterShareButton>
      </ListItem>{' '}
      <ListItem w="40px" m="0 0.5vw">
        <LinkedinShareButton url={`${config.BASE_PATH}${url}`} title={''}>
          <Link mb={{ base: 0, sm: 0, md: 0, lg: '10px' }} display="block">
            <Box
              bgImage={`url(${config.staticPath}/assets/images/linkedin.svg)`}
              bgPosition="center"
              bgRepeat="no-repeat"
              _hover={{
                bgImage: `url('${config.imgPath}/d/46272/1663580369-linkedin1.svg')`,
              }}
              w="28px"
              h="28px"
              bgSize="clip"
            ></Box>
          </Link>
        </LinkedinShareButton>
      </ListItem>{' '}
      <ListItem w="40px" m="0 0.5vw">
        <EmailShareButton url={`${config.BASE_PATH}${url}`} string="">
          <Link mb={{ base: 0, sm: 0, md: 0, lg: '10px' }} display="block">
            <Box
              bgImage={`url(${config.staticPath}/assets/images/message.svg)`}
              bgPosition="center"
              bgRepeat="no-repeat"
              _hover={{
                bgImage: `url('${config.staticPath}/assets/images/message1.svg')`,
              }}
              w="28px"
              h="28px"
              bgSize="clip"
            ></Box>
          </Link>
        </EmailShareButton>
      </ListItem>
      {freeSadhguruDownloadsId && (
        <ListItem w="40px" m="0 0.5vw">
          <Link
            href={`${config.cdnPath}/getdownload/video/get_link/${freeSadhguruDownloadsId}/en`}
            mb={{ base: 0, sm: 0, md: 0, lg: '10px' }}
            isExternal
            display="block"
            _focus={{ boxShadow: 'none' }}
          >
            <Box
              bgImage={`url('${config.imgPath}/d/46272/1663648709-download.svg')`}
              bgPosition="center"
              bgRepeat="no-repeat"
              w="28px"
              h="28px"
              bgSize="clip"
            ></Box>
          </Link>
        </ListItem>
      )}
      {isLikeBookmarkVisible && (
        <ListItem
          w={{ base: '70px', sm: '70px', md: '40px' }}
          borderTop={{ base: 'unset', sm: 'unset', md: '1px solid #dfd6c4' }}
          pb="30px"
          m="0 0.5vw"
        >
          <Box pt={{ base: '2px', sm: '2px', md: '16px' }}>
            <LikeBookmark
              uiFlex={{ base: 'row', sm: 'row', md: 'column' }}
              updateCards={updateCards}
              isLiked={isLiked}
              isBookmarked={isBookmarked}
            />
          </Box>
        </ListItem>
      )}
    </List>
  );
};

export default SocialIcon;
