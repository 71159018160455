/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SocialIcon from '@components/Utility/SocialIcon';
import config from '@config';

/* Services */
/* Styles */

/**
 * @param {string} url -link
 * @param {Object} sectionContent
 * @returns
 */
const SocialLinks = ({ url }) => {
  return (
    <Box>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        maxW={723}
        justifyContent="center"
        alignItems="flex-start"
        mx="auto"
        w={{ xl: '90vw', base: '50%' }}
        margin="0 auto"
      >
        <LazyLoadImageComponent
          alt="divider"
          title="Divider"
          src={`${config.imgPath}/d/46272/1663651708-kolam-hash.png`}
          // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/kolam-hash.png"
          transform="rotate(180deg)"
          margin={{ base: '0 auto', md: '50px 0' }}
          h={'17px'}
          w="150px"
        />
        <Box
          // width={{ sm: '30%', xl: "60%" }}
          // ml={{ lg: "-100px", sm: "-90px" }}
          // mr="50px"
          margin={{ base: '0 auto', md: '45px 100px' }}
        >
          <SocialIcon
            url={url}
            isVisible={true}
            direction={'row'}
            position={'relative'}
            isLikeBookmarkVisible={false}
          />
        </Box>

        <LazyLoadImageComponent
          alt="divider"
          title="Divider"
          src={`${config.imgPath}/d/46272/1663651708-kolam-hash.png`}
          // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/kolam-hash.png"
          transform="rotate(180deg)"
          margin={{ base: '0 auto', md: '50px 0' }}
          h={'17px'}
          w="150px"
        />
      </Flex>
    </Box>
  );
};

export default SocialLinks;
